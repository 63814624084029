import React from 'react';
import { graphql, Link } from 'gatsby';
import { Container, Row, Col } from 'react-bootstrap';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Footer from '../components/footer';
import SocialShare from '../components/socialShare';

const NotFoundPage = ({ data, location }) => {
  const dataOptions = {
    url: location.href,
    title: `Page Not Found - ${data.site.siteMetadata.title}`,
  };

  const goBack = () => {
    window.history.back();
  };

  return (
    <Layout classContainer="landing-page-bg">
      <SEO
        title={dataOptions.title}
        url={dataOptions.url}
      />
      <div id="notfound-info-wrapper" className="main-fixed-wrapper">
        <Container fluid="lg">
          <Row className="reveal-animate justify-content-center content-row text-center align-items-center">
            <Col lg={6} md={12}>
              <h2 className="notfound-heading reveal-animate text-center">404</h2>
              <h3 className="notfound-sub-heading site-label">Page Not Found</h3>

              <div className="notfound-content mt-5">
                <p>We are very sorry for inconvenienve. <br /> It looks you're tying to access a page that either has been deleted or never been existed.</p>

                <a href="/" className="back-link mt-5">
                  <span className="explore-arrow position-relative">
                    <span className="long-arrow left-arrow" />
                    <span className="label">Back to Timeline</span>
                  </span>
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <SocialShare dataOptions={dataOptions} />
      <Footer>
        <div id="footer-color" />
      </Footer>
    </Layout>
  );
}

export default NotFoundPage;

export const query = graphql`
  {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
