import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { Container, Row, Col } from 'react-bootstrap';
import KNLogo from '../../static/kn-footer-logo.png';
import ScreenSirenLogo from '../../static/screen-siren-logo.png';
import CMFLogo from '../../static/cmf-logo.png';
import RogersDocFund from '../../static/rogers-doc-fund-logo.png';
import RogersTelefund from '../../static/rogers-telefund-logo.png';

const Footer = ({ children }) => {

  const data = useStaticQuery(graphql`
		query {
			site {
				siteMetadata {
					kn_info {
            name
            link
          }
					ss_info {
            name
            link
          }
					cmf_info {
            name
            link
          }
					rogers_telefund {
            name
            link
          }
					rogers_doc_fund {
            name
            link
          }
				}
			}
		}
	`);

  return (
    <div className="site-footer">
      <Container fluid>
        <Row className="align-items-center justify-content-center reveal-animate">
          <Col xxl={1} xl={2} lg={2} md={3} sm={4} xs={12} className="order-2 order-sm-0">
            <div className="footer-logo mb-4 mb-sm-0">
              <a href={data.site.siteMetadata.ss_info.link} target="_blank">
                <img src={ScreenSirenLogo} alt={data.site.siteMetadata.ss_info.name} width="150" height="60" />
              </a>
            </div>
          </Col>
          <Col xxl={2} xl={2} lg={3} md={4} sm={4} xs={12} className="order-1 order-sm-0">
            <div className="footer-logo">
              <a href={data.site.siteMetadata.kn_info.link} target="_blank">
                <img src={KNLogo} alt={data.site.siteMetadata.kn_info.name} width="200" height="80" />
              </a>
            </div>
          </Col>
          <Col xl={1} xl={2} lg={2} md={3} sm={4} xs={12} className="order-3 order-sm-0">
            <div className="footer-logo py-2">
              <a href={data.site.siteMetadata.cmf_info.link} target="_blank">
                <img src={CMFLogo} alt={data.site.siteMetadata.cmf_info.name} width="150" height="35" />
              </a>
            </div>
          </Col>
        </Row>
        <Row className="align-items-center justify-content-center reveal-animate">
          <Col xl={2} lg={3} md={3} sm={4} xs={12}>
            <div className="footer-logo mb-4 mt-4 mt-sm-3">
              <a href={data.site.siteMetadata.rogers_doc_fund.link} target="_blank">
                <img src={RogersDocFund} alt={data.site.siteMetadata.rogers_doc_fund.name} />
              </a>
            </div>
          </Col>
          <Col xl={1} lg={3} md={3} sm={4} xs={12}>
            <div className="footer-logo mb-4 mt-3">
              <a href={data.site.siteMetadata.rogers_telefund.link} target="_blank">
                <img src={RogersTelefund} alt={data.site.siteMetadata.rogers_telefund.name} />
              </a>
            </div>
          </Col>
        </Row>
      </Container>

      <Container>
        <Row className="justify-content-center reveal-animate">
          <Col xs={4} sm={2} lg={1}>
            <div id="credits-button" className="mt-5">
              <Link
                to="/about"
              >About</Link>
            </div>
          </Col>
          <Col xs={4} sm={2} lg={1}>
            <div id="credits-button" className="mt-5">
              <Link
                to="/credits"
              >Credits</Link>
            </div>
          </Col>
        </Row>
      </Container>

      {children}
    </div>
  );
};

export default Footer;
